import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "module-3-questions-and-answers"
    }}>{`Module 3 questions and answers`}</h1>
    <p>{`These were some questions people asked during the live recording. You might have thought similar questions while going through the module.`}</p>
    <h2 {...{
      "id": "does-graphql-require-dynamodb"
    }}>{`Does GraphQL require DynamoDB?`}</h2>
    <Vimeo id="392218625" mdxType="Vimeo" />
    <h2 {...{
      "id": "why-is-expression-and-values-separate"
    }}>{`Why is expression and values separate?`}</h2>
    <Vimeo id="392219524" mdxType="Vimeo" />
    <h2 {...{
      "id": "are-dynamodb-keys-like-relations-in-a-traditional-db"
    }}>{`Are DynamoDB keys like relations in a traditional DB?`}</h2>
    <Vimeo id="392219691" mdxType="Vimeo" />
    <h2 {...{
      "id": "whats-the-difference-between-usequery-and-usemutation"
    }}>{`What's the difference between useQuery and useMutation?`}</h2>
    <Vimeo id="392216839" mdxType="Vimeo" />
    <h2 {...{
      "id": "should-you-use-usemutation-centrally"
    }}>{`Should you use useMutation centrally?`}</h2>
    <Vimeo id="392225933" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      